import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { selectUserIsAuthenticated } from '@core/store/selectors/core.selectors';
import { environment } from '@env/environment';
import {
  ICampaign,
  ICampaignResult,
  IConductMeetingResult,
  ICreateCampaign,
  ICreateMeeting,
  IMeeting,
  IMeetingResult,
  IPatchMeeting,
  IPostMeetingConduct,
  IPrepareMeetingResult,
} from '@meeting/store/models/meeting.model';
import { IParticipantAnswerPrepare, IParticipantAnswerResult } from '@meeting/store/models/participant-answers.model';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MeetingService {
  private _ENDPOINT_URL = `${environment.API_URL}/meeting`;
  private _meetings$$: BehaviorSubject<IMeeting[]> = new BehaviorSubject<
    IMeeting[]
  >([]);
  _meetings$ = this._meetings$$.asObservable();

  constructor(
    private http: HttpClient,
    private _store: Store,
  ) {
    this._store.pipe(select(selectUserIsAuthenticated)).subscribe((isAuth) => {
      if (isAuth) this.refreshMeetings();
    });
  }

  refreshMeetings() {
    this.getAll()
      .pipe(take(1))
      .subscribe((meetings) => this._meetings$$.next(meetings));
  }

  getAll(): Observable<IMeeting[]> {
    return this.http.get<IMeeting[]>(this._ENDPOINT_URL + '/meetings/v2').pipe(
      map((meetings) => {
        return meetings.map((meeting) => {
          return {
            ...meeting,
            participants: meeting.participants.map((p) => {
              return {
                ...p,
                user: p.user ?? {
                  color: p.color,
                  firstname: p.firstName,
                  lastname: p.lastName,
                  initials: p.initials,
                },
              };
            }),
          };
        });
      }),
    );
  }

  getAllCampaigns(): Observable<ICampaign[]> {
    return this.http
      .get<ICampaign[]>(this._ENDPOINT_URL + '/meetings/campaign')
      .pipe();
  }

  getOne(meetingId: string): Observable<IMeetingResult> {
    return this.http.get<IMeetingResult>(
      this._ENDPOINT_URL + '/meetings/' + meetingId,
    );
  }

  getDraft(meetingId: string): Observable<IPrepareMeetingResult> {
    return this.http.get<IPrepareMeetingResult>(
      this._ENDPOINT_URL + '/meetings/' + meetingId,
    );
  }

  getPrepare(meetingId: string): Observable<IPrepareMeetingResult> {
    return this.http.get<IPrepareMeetingResult>(
      this._ENDPOINT_URL + '/meetingsSteps/prepare/' + meetingId,
    );
  }

  startOne(meetingId: string): Observable<IMeetingResult> {
    return this.http.post<IMeetingResult>(
      this._ENDPOINT_URL + '/meetingsScheduler/start',
      { id: meetingId },
    );
  }

  getMultiple(meetingIds: string[]): Observable<IMeetingResult[]> {
    const meetingRequests$ = combineLatest(
      meetingIds.map((meetingId) => this.getOne(meetingId)),
    );
    return meetingRequests$;
  }

  getPrepareMeetingAnswer(
    meetingId: string,
  ): Observable<IPrepareMeetingResult> {
    return this.http.get<IPrepareMeetingResult>(
      this._ENDPOINT_URL + '/meetingsSteps/prepare/' + meetingId,
    );
  }

  getConductMeetingAnswer(
    meetingId: string,
  ): Observable<IConductMeetingResult> {
    return this.http.get<IConductMeetingResult>(
      this._ENDPOINT_URL + '/meetingsSteps/conduct/' + meetingId,
    );
  }

  postMeetingAnswer(
    meetingId: string,
    questions: IParticipantAnswerPrepare[],
  ): Observable<IPrepareMeetingResult> {
    return this.http.post<IPrepareMeetingResult>(
      this._ENDPOINT_URL + '/meetingsSteps/prepare/' + meetingId,
      { questions },
    );
  }

  postMeetingAnswerDraft(
    meetingId: string,
    questions: IParticipantAnswerPrepare[],
  ): Observable<IParticipantAnswerResult[]> {
    return this.http.post<IParticipantAnswerResult[]>(
      this._ENDPOINT_URL + '/meetingsSteps/prepare/' + meetingId + '/draft',
      { questions },
    );
  }

  postConductMeetingAnswer(
    meetingId: string,
    payload: IPostMeetingConduct,
  ): Observable<IMeetingResult> {
    return this.http.post<IMeetingResult>(
      this._ENDPOINT_URL + '/meetingsSteps/conduct/' + meetingId,
      payload,
    );
  }

  postSignMeeting(
    meetingId: string,
    payload: { signed: boolean; comment?: string },
  ): Observable<IMeetingResult> {
    return this.http.post<IMeetingResult>(
      this._ENDPOINT_URL + '/meetingsSteps/sign/' + meetingId,
      payload,
    );
  }

  create(meeting: ICreateMeeting): Observable<IMeetingResult> {
    return this.http.post<IMeetingResult>(
      this._ENDPOINT_URL + '/meetings',
      meeting,
    );
  }

  createCampaign(campaign: ICreateCampaign): Observable<ICampaignResult> {
    return this.http.post<ICampaignResult>(
      this._ENDPOINT_URL + '/meetings/campaign',
      campaign,
    );
  }

  patch(
    meetingId: string,
    meetingToPatch: IPatchMeeting,
  ): Observable<IMeetingResult> {
    return this.http.patch<IMeetingResult>(
      `${this._ENDPOINT_URL}/meetings/${meetingId}`,
      meetingToPatch,
    );
  }

  delete(id: string): Observable<IMeetingResult> {
    return this.http.delete<IMeetingResult>(
      `${this._ENDPOINT_URL}/meetings/${id}`,
    );
  }
}
