import { BleexoLocale } from '@models/bleexo.models';
import { User } from '@models/user.model';
import { ITemplateQuestions, ITemplateResult } from './meeting-template.model';
import { IParticipantAnswerResult } from './participant-answers.model';

export enum EMeetingStatuses {
  CREATED = 'CREATED',
  STARTED = 'STARTED',
  PREPARED_BY_REVIEWER = 'PREPARED_BY_REVIEWER',
  PREPARED_BY_REVIEWEE = 'PREPARED_BY_REVIEWEE',
  PREPARED_BY_BOTH = 'PREPARED_BY_BOTH',
  READY_TO_SIGN = 'READY_TO_SIGN',
  FINISHED_BY_REVIEWEE = 'FINISHED_BY_REVIEWEE',
  FINISHED_BY_REVIEWER = 'FINISHED_BY_REVIEWER',
  CLOSED_BY_BOTH = 'CLOSED_BY_BOTH',
}

export enum EParticipantRoles {
  REVIEWEE = 'REVIEWEE',
  REVIEWER = 'REVIEWER',
  ALL = 'ALL',
}

export interface IMeeting {
  id: string;
  companyId: number;
  createdAt: Date;
  updatedAt: Date | null;
  archivedAt: Date | null;
  locale: BleexoLocale;
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
  reminders: Date[];
  status: EMeetingStatuses;
  templateId: string;
  typeId: string;
  summary: string | null;
  reportFileId: number | null;
  type?: string;
  participants?: IParticipantResult[];
  campaignId?: string;
}

export interface ICreateMeeting {
  title: string;
  description: string;
  companyId?: number;
  locale: BleexoLocale;
  templateId: string;
  revieweeId: number;
  reviewerId: number;
  reminders: string[];
  startDate: string;
  endDate: string;
}

export interface ICreateCampaign {
  title: string;
  description: string;
  companyId?: number;
  locale: BleexoLocale;
  templateId: string;
  participants: Array<{ revieweeId: number; reviewerId: number }>;
  reminders: string[];
  startDate: string;
  endDate: string;
}

export interface IPatchMeeting {
  title?: string;
  description?: string;
  locale?: BleexoLocale;
  templateId?: string;
  revieweeId?: number;
  reviewerId?: number;
  reminders?: Date[];
  startDate?: Date;
  endDate?: Date;
}

export interface IArchiveMeeting {}

export interface IGetMeeting {}

export interface IFindMeeting {
  companyId?: number;
  typeId?: string;
  status?: EMeetingStatuses;
}

export interface IParticipant {
  id: string;
  companyId: number;
  createdAt: Date;
  updatedAt: Date | null;
  archivedAt: Date | null;
  role: EParticipantRoles;
  meetingId: string;
  userId: number;
  canShare: boolean;
  signed: boolean | null;
  unSignedComment: string | null;
  finishedDate: Date | null;
}

export interface IParticipantSegment {
  id: string;
  companyId: number;
  createdAt: Date;
  updatedAt: Date | null;
  archivedAt: Date | null;
  participantId: string;
  segmentId: number;
}

export interface IMeetingNote {
  id: string;
  companyId: number;
  createdAt: Date;
  updatedAt: Date | null;
  archivedAt: Date | null;
  meetingId: string;
  content: string;
  order: number;
}

export interface IMeetingResult extends IMeeting {
  questions: ITemplateQuestions[];
  notes?: IMeetingNote[];
  participants: IParticipantResult[];
  questionComments?: IMeetingConductAnswer[];
  template?: ITemplateResult;
}

export interface ICampaignResult extends ICampaign {
  meetings?: {
    count: number,
    ids: string[]
  };
}

export interface ICampaign extends ITable {
  startDate: Date | null;
  endDate: Date | null;
  createdBy: number;
  status: ECampaignStatus;
  title: string;
}

export enum ECampaignStatus {
  CREATED = 'CREATED',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
}

export interface ITable {
  id: string;
  companyId: number;
  createdAt: Date | null;
  updatedAt: Date | null;
  archivedAt: Date | null;
}

export interface IMeetingConductAnswer {
  id: string;
  companyId: number;
  createdAt: Date;
  updatedAt: Date | null;
  archivedAt: Date | null;
  meetingId: string;
  questionId: string;
  comment: string | null;
}

export interface IParticipantResult extends IParticipant {
  user: Partial<User> | User;
  segments?: IParticipantSegment[];
  answers?: IParticipantAnswerResult[];
  firstName?: string;
  color?: string;
  initials?: string;
  lastName?: string;
}
export interface IPrepareMeetingResult extends IMeeting {
  notes: IMeetingNote[];
  participant: IParticipant;
  segments: IParticipantSegment[];
  answers: IParticipantAnswerResult[];
}
export interface IPostMeetingConduct {
  questions: Array<{
    id: string;
    comment: string;
  }>;
  summary: string;
}

export interface IConductMeetingResult extends IMeeting {
  notes: IMeetingNote[];
  participants: IParticipantResult[];
  segments: IParticipantSegment[];
  answers: IParticipantAnswerResult[];
}

export interface IPrepareMeetingResult extends IMeeting {
  notes: IMeetingNote[];
  participant: IParticipant;
  segments: IParticipantSegment[];
  answers: IParticipantAnswerResult[];
}
